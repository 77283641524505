import UserService from '@/services/UserService';
import { normalizeUser } from '@/utils';

export const state = () => ({
  list: [],
  listType: 'all', // 'all' 'unapproved', 'banned'
  pagination: {
    page: 1,
    perPage: 50,
    total: 0
  }
});

export const getters = {
  list: (state) => state.list,
  total: (state) => state.pagination.total,
  pagination: (state) => state.pagination
};

export const mutations = {
  setPagination(state, payload) {
    let total;
    if (Number.isInteger(parseInt(payload.total))) {
      total = payload.total;
    } else {
      total = state.pagination.total;
    }
    state.pagination = {
      page: payload.page || state.pagination.page,
      perPage: payload.perPage || state.pagination.perPage,
      total
    };
  },
  setList(state, payload) {
    state.list = payload;
  },
  setListType(state, payload) {
    state.listType = payload;
  }
};

export const actions = {
  async verifyUser({ state, dispatch }, id) {
    const result = await UserService.verify(id);
    dispatch('getUsers', state.listType);
    return result;
  },

  async banUser({ state, dispatch }, payload) {
    const result = await UserService.ban(payload);
    dispatch('getUsers', state.listType);
    return result;
  },

  async unbanUser({ state, dispatch }, id) {
    const result = await UserService.unban(id);
    dispatch('getUsers', state.listType);
    return result;
  },

  async deleteUser({ state, dispatch }, id) {
    const result = await UserService.delete(id);
    dispatch('getUsers', state.listType);
    return result;
  },

  async getUserById({ commit }, { id }) {
    commit('setList', []);
    const result = await UserService.getById(id);
    const list = [normalizeUser(result)];
    commit('setList', list);
    return result;
  },

  async getUsersByType({ commit }, { type, ...query }) {
    let result;
    if (type === 'all') {
      result = await UserService.all(query);
    } else if (type === 'unapproved') {
      result = await UserService.getByVerifyStatus({ status: false, ...query });
    } else if (type === 'banned') {
      result = await UserService.getBanned({ ...query });
    }
    const list = result.data.map(normalizeUser);
    commit('setPagination', { ...query, total: result.count });
    commit('setList', list);
    return result;
  },

  async searchUsers({ commit }, payload) {
    const result = await UserService.search(payload);
    const list = result.data.map(normalizeUser);
    commit('setList', list);
    commit('setPagination', { ...payload, total: result.count });
    return result;
  },

  async getUsers({ commit, dispatch, rootState }, type) {
    commit('setPagination', { total: 0 });
    commit('setListType', type);
    const query = rootState.route.query;

    if (query.id) {
      await dispatch('getUserById', { id: query.id, ...query });
    } else if (query.search) {
      await dispatch('searchUsers', query);
    } else {
      await dispatch('getUsersByType', { type, ...query });
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

// import moduleTodo from './todo/moduleTodo.js';
// import moduleCalendar from './calendar/moduleCalendar.js';
// import moduleChat from './chat/moduleChat.js';
// import moduleEmail from './email/moduleEmail.js';
import moduleAuth from './auth/moduleAuth.js';
// import moduleECommerce from './eCommerce/moduleECommerce.js';
import moduleTopics from './dio/moduleTopics.js';
import moduleEntry from './dio/moduleEntry.js';
import moduleUser from './dio/moduleUser.js';
import moduleComplaint from './dio/moduleComplaint.js';
import moduleBroadcast from './dio/moduleBroadcast.js';
import moduleAudit from './dio/moduleAudit.js';
import moduleInstagram from './dio/moduleInstagram.js';

const vueLocalStorage = new VuexPersist({
  key: 'dıoadmın',
  storage: window.localStorage,
  reducer: (state) => ({
    topics: {
      mergeList: state.topics.mergeList
    }
  })
});

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    // calendar: moduleCalendar,
    // chat: moduleChat,
    // email: moduleEmail,
    auth: moduleAuth,
    // eCommerce: moduleECommerce,
    topics: moduleTopics,
    entry: moduleEntry,
    user: moduleUser,
    complaint: moduleComplaint,
    broadcast: moduleBroadcast,
    audit: moduleAudit,
    instagram: moduleInstagram
  },
  plugins: [vueLocalStorage.plugin],
  strict: process.env.NODE_ENV !== 'production'
});

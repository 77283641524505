import axios from 'axios';
import LogService from '@/services/LogService';
import { errorWrapper } from '@/utils/serviceUtils';
import Vue from 'vue';

const instance = axios.create({
  // baseURL: 'http://localhost:8080'
});

const invalidToken = (error) => {
  const invalidTokenErrorText = 'Invalid Token';
  const status = error.response.status;
  const message = error.response.data.hasOwnProperty('message') ? error.response.data.message : error.response.data.status.message;
  return status === 401 && message === invalidTokenErrorText;
};

instance.interceptors.response.use(null, (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status <= 500;
  const message = error.response.data ? error.response.data.status.message : error.response.statusText;
  if (expectedError) {
    const errorWrapped = errorWrapper(error, message);
    LogService.log(errorWrapped);
    if (invalidToken(error)) {
      window.location.href = '/pages/login';
    } else {
      Vue.$swal({
        icon: 'error',
        title: 'Hata',
        text: errorWrapped.message
      });
    }
  } else {
    return Promise.reject(errorWrapper(error, message));
  }
});

export default instance;

import TopicService from '@/services/TopicService';
import { normalizeTopic, normalizeEntry } from '@/utils';
import EntryService from '@/services/EntryService';

export const state = () => ({
  list: [],
  listType: 'all', // 'all' 'deleted'
  topic: null,
  pagination: {
    page: 1,
    perPage: 50,
    total: 0
  }
});

export const getters = {
  list: (state) => state.list,
  topic: (state) => state.topic,
  total: (state) => state.pagination.total,
  pagination: (state) => state.pagination,
  currentState: (state) => {
    let statesType = {
      all: false,
      deleted: false,
      waiting: false,
      approved: false,
      restored: false
    };
    statesType[state.topicListType] = true;
    return statesType;
  }
};

export const mutations = {
  setPagination(state, payload) {
    let total;
    if (Number.isInteger(parseInt(payload.total))) {
      total = payload.total;
    } else {
      total = state.pagination.total;
    }
    state.pagination = {
      page: payload.page || state.pagination.page,
      perPage: payload.perPage || state.pagination.perPage,
      total
    };
  },
  setList(state, payload) {
    state.list = payload;
  },
  setTopic(state, payload) {
    state.topic = payload;
  },
  setListType(state, payload) {
    state.listType = payload;
  }
};

export const actions = {
  async approveEntry({ state, dispatch }, id) {
    const result = await EntryService.approve(id);
    dispatch('getEntries', state.listType);
    return result;
  },
  async deleteEntry({ state, dispatch }, id) {
    const result = await EntryService.delete(id);
    dispatch('getEntries', state.listType);
    return result;
  },
  async restoreEntry({ state, dispatch }, id) {
    const result = await EntryService.restore(id);
    dispatch('getEntries', state.listType);
    return result;
  },
  async updateEntry({ state, dispatch }, payload) {
    const result = await EntryService.update(payload);
    normalizeEntry(result);
    dispatch('getEntries', state.listType);
    return result;
  },
  async searchEntry({ commit }, payload) {
    commit('setList', []);
    const result = await EntryService.search(payload);
    const list = result.data.map(normalizeEntry);
    commit('setList', list);
    commit('setPagination', { ...payload, total: result.count });
    return result;
  },
  async getEntriesById({ commit }, { id, ...query }) {
    let result = await TopicService.getEntriesById({ id, ...query });
    const list = result.data.map(normalizeEntry);
    const topic = normalizeTopic(result.data[0].topic);
    commit('setTopic', topic);
    commit('setPagination', { ...query, total: result.count });
    commit('setList', list);
    return result;
  },

  async getEntryById({ commit }, { id }) {
    commit('setList', []);
    const result = await EntryService.getById(id);
    const list = [normalizeEntry(result)];
    commit('setList', list);
    return result;
  },

  async getEntriesByType({ commit }, { type, ...query }) {
    let result = await EntryService.all({ type, ...query });
    result.data.map(normalizeEntry);
    commit('setPagination', { ...query, total: result.count });
    commit('setList', result.data);
    return result;
  },
  async getTopicById({ commit }, id) {
    const result = await TopicService.getById(id);
    normalizeTopic(result);
    commit('setTopic', result);
    return result;
  },
  async clearTopic({ commit }) {
    commit('setTopic', null);
  },
  async getEntries({ rootState, commit, dispatch }, type) {
    commit('setListType', type);
    commit('setPagination', { total: 0 });
    const query = rootState.route.query;
    const topicId = rootState.route.query.topicId;
    const id = rootState.route.query.id;
    await dispatch('clearTopic');
    if (topicId) {
      await dispatch('getEntriesById', { id: topicId, ...query });
    } else if (id) {
      await dispatch('getEntryById', { id, ...query });
    } else if (query.search) {
      await dispatch('searchEntry', query);
    } else {
      await dispatch('getEntriesByType', { type, ...query });
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          redirect: () => {
            if (localStorage.getItem('user')) {
              return 'dashboard/topic';
            } else {
              return '/pages/login';
            }
          }
        },

        {
          path: '/dashboard/topics',
          // name: 'topic',
          component: () => import('./views/components/extra-components/topics/Topic.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/dashboard/topic',
          name: 'topic',
          component: () => import('./pages/topic/Topic.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/dashboard/entry',
          name: 'entry',
          component: () => import('./pages/entry/Entry.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/dashboard/user',
          name: 'user',
          component: () => import('./pages/user/User.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/instagram',
          name: 'instagram',
          component: () => import('./pages/instagram/Instagram.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/complaint',
          name: 'complaint',
          component: () => import('./pages/complaint/Complaints.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/broadcast',
          name: 'broadcast',
          component: () => import('./pages/broadcast/Broadcast.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/audit',
          name: 'audit',
          component: () => import('./pages/audit/Audit.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/complaint/detail',
          name: 'complaints-detail',
          component: () => import('./pages/complaint/ComplaintsDetail.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/complaint/detail',
          name: 'complaint-detail',
          component: () => import('./pages/complaint/ComplaintDetail.vue'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: '/dashboard/entrys',
          name: 'entrys',
          component: () => import('./views/components/extra-components/entries/Entry.vue'),
          meta: {
            rule: 'admin'
          }
        }
      ]
    },
    //
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;

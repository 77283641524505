import ComplaintService from '@/services/ComplaintService';
import { normalizeEntry, normalizeTopic, normalizeUser } from '@/utils';

export const state = () => ({
  list: [],
  listType: 'all', // 'all' 'unapproved', 'banned'
  listDetail: [],
  listDetailTarget: null,
  pagination: {
    page: 1,
    perPage: 50,
    total: 0
  },
  filterData: []
});

export const getters = {
  list: (state) => state.list,
  listDetail: (state) => state.listDetail,
  listDetailTarget: (state) => state.listDetailTarget,
  total: (state) => state.pagination.total,
  pagination: (state) => state.pagination
};

export const mutations = {
  setPagination(state, payload) {
    let total;
    if (Number.isInteger(parseInt(payload.total))) {
      total = payload.total;
    } else {
      total = state.pagination.total;
    }
    state.pagination = {
      page: payload.page || state.pagination.page,
      perPage: payload.perPage || state.pagination.perPage,
      total
    };
  },
  setList(state, payload) {
    state.list = payload;
  },

  setListDetail(state, payload) {
    state.listDetail = payload;
  },

  setListDetailTarget(state, payload) {
    state.listDetailTarget = payload;
  },

  setListType(state, payload) {
    state.listType = payload;
  }
};

export const actions = {
  async getListDetail({ commit, rootState }) {
    const query = rootState.route.query;
    commit('setPagination', { total: 0 });
    commit('setListDetail', []);
    commit('setListDetailTarget', null);

    const payload = { ...query, target: query.id, type: query.type.toUpperCase() };

    const result = await ComplaintService.getByTarget(payload);

    if (query.type === 'entry') {
      result.target = normalizeEntry(result.target);
    }
    if (query.type === 'topic') {
      result.target = normalizeTopic(result.target);
    }
    if (query.type === 'user') {
      result.target = normalizeUser(result.target);
    }

    commit('setPagination', { total: parseInt(result.data.count) });
    commit('setListDetail', result.data);
    commit('setListDetailTarget', result.target);
  },
  async getDetail({ commit, rootState }) {
    const query = rootState.route.query;
    commit('setPagination', { total: 0 });
    commit('setListDetail', []);
    commit('setListDetailTarget', null);

    const payload = { ...query, target: query.userId, type: query.type.toUpperCase() };
    const result = await ComplaintService.getByTarget(payload);

    commit('setPagination', { total: parseInt(result.data.count) });
    commit('setListDetail', result.data);
    commit('setListDetailTarget', result.target);
  },
  async getList({ commit, rootState }, type) {
    let result = [];
    commit('setList', []);
    commit('setListType', type);
    commit('setPagination', { total: 0 });

    const query = rootState.route.query;
    const payload = { ...query, type: type.toUpperCase() };

    result = await ComplaintService.getByType(payload);

    const normalizeData = (normalizer) => {
      result.data = result.data.map((complaint) => {
        complaint.data = normalizer(complaint.data);
        return complaint;
      });
    };

    if (type === 'entry') {
      normalizeData(normalizeEntry);
    }

    if (type === 'topic') {
      normalizeData(normalizeTopic);
    }

    if (type === 'user') {
      normalizeData(normalizeUser);
    }

    commit('setPagination', { total: parseInt(result.count) });
    commit('setList', result.data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

import http from './http';
import { authHeader } from '@/utils';
import { API_URL } from '@/constants/config';

const base = API_URL + '/v1/1/4/1';

class UploadService {
  /**
   * Upload a image
   * @param {*} formdata
   *
   */
  async upload(formData) {
    const { data: result } = await http.post(`${base}/image/upload`, formData, {
      headers: authHeader(),
      'Content-Type': 'multipart/form-data'
    });
    return result.data;
  }
}
export default new UploadService();

import http from './http';
import qs from 'qs';
import { authHeader } from '@/utils';
import { SEARCH_URL, API_URL } from '@/constants/config';

const base = API_URL + '/v1/1/4/1';
const baseSearch = SEARCH_URL + '/v1/1/4/4/search/entry';

class EntryService {
  /**
   * All entries
   */
  async all({ type = 'all', perPage = 50, page = 1, orderKey, orderValue } = {}) {
    const params = qs.stringify({ [type]: true, page, perPage, orderKey, orderValue }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/entry/list${params}`, { headers: authHeader() });
    return result.data;
  }

  /**
   * Get entry by id
   * @param {String} id
   */
  async getById(id) {
    const { data: result } = await http.get(`${base}/entry/getById/${id}`, { headers: authHeader() });
    return result.data;
  }

  /**
   * Approve a entry
   * @param {*} id
   *
   */
  async approve(id) {
    const params = qs.stringify({ entryId: id }, { addQueryPrefix: true });
    const { data: result } = await http.post(`${base}/entry/approve${params}` + id, {}, { headers: authHeader() });
    return result.data;
  }

  /**
   * Update a entry
   * @param {*} param
   *
   */
  async update({ entry }) {
    const body = entry;
    const { data: result } = await http.put(`${base}/entry/update`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Delete entry
   *
   * @param {*} id
   */
  async delete(id) {
    const { data: result } = await http.delete(`${base}/entry/delete/soft/${id}`, { headers: authHeader() });
    return result.data;
  }

  /**
   * Restore entry
   *
   * @param {[]} id
   */
  async restore(id) {
    const body = {
      entryId: id
    };
    const { data: result } = await http.post(`${base}/entry/restore`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Change entry vote counts
   *
   * @param {Object} param object
   * @param {string} poram.id entry id
   * @param {number} poram.like upvote count
   * @param {number} poram.dislike downvote count
   */
  async vote({ id, like, dislike }) {
    const body = {
      entryId: id,
      upCount: like,
      downCount: dislike
    };
    const { data: result } = await http.post(`${base}/vote/set`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Search entries
   *
   * @param {search: string, page: string, perPage: string} topic
   */
  async search({ search = '', perPage = 50, page = 1 }) {
    const params = qs.stringify({ q: search, page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${baseSearch}${params}`, { headers: authHeader() });
    return { data: result.data.paginatedSearchResult, count: result.data.totalCount };
  }
}
export default new EntryService();

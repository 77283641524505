import InstagramService from '@/services/InstagramService';
export const state = () => ({
  list: [],
  pagination: {
    page: 1,
    perPage: 50,
    total: 0
  }
});

export const getters = {
  list: (state) => state.list,
  pagination: (state) => state.pagination
};

export const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  setPagination(state, payload) {
    let total;
    if (Number.isInteger(parseInt(payload.total))) {
      total = payload.total;
    } else {
      total = state.pagination.total;
    }
    state.pagination = {
      page: payload.page || state.pagination.page,
      perPage: payload.perPage || state.pagination.perPage,
      total
    };
  }
};

export const actions = {
  async getList({ commit, rootState }) {
    const query = rootState.route.query;
    commit('setPagination', { total: 0 });
    const result = await InstagramService.getAll(query);

    commit('setPagination', { total: parseInt(result.count) });
    commit('setList', result.topics);
    return result;
  },

  async addInstagramBridge(_, { id, thumbnail }) {
    const payload = { id, thumbnail };
    const result = await InstagramService.addTopic(payload);
    return result;
  },

  async removeTopic({ dispatch }, id) {
    const result = await InstagramService.removeTopic(id);
    dispatch('getList');
    return result;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

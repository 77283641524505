import AuditService from '@/services/AuditService';
import { normalizeEntry, normalizeTopic, normalizeUser } from '@/utils';
import { sortBy } from 'lodash-es';

const actionTypes = {
  APPROVE_TOPIC: 'Başlık Onaylandı',
  MERGE_TOPIC: 'Başlık Birleştirildi',
  RESTORE_TOPIC: 'Başlık Geri Getirildi',
  DELETE_TOPIC: 'Başlık Silindi',
  UPDATE_TOPIC: 'Başlık Güncellendi',
  PIN_TOPIC: 'Başlık Sabitlendi',
  LOCK_TOPIC: 'Başlık Kilitlendi',
  PICK_TOPIC: 'Başlık Seçmeceye Alındı',
  SET_THUMBNAIL: 'Başlık Görseli Güncellendi',
  SET_VOTE: 'Başlık Oy Sayısı Güncellendi',
  UPDATE_ENTRY: 'Dio Güncellendi',
  DELETE_ENTRY: 'Dio Silindi',
  RESTORE_ENTRY: 'Dio Geri Getirildi',
  UNBAN_USER: 'Kullanıcı Banı Kaldırıldı',
  BAN_USER: 'Kullanıcı Banlandı',
  DELETE_USER: 'Kullanıcı Silindi'
};

export const state = () => ({
  list: [],
  actions: [],
  users: [],
  pagination: {
    page: 1,
    perPage: 50,
    total: 0
  }
});

export const getters = {
  list: (state) => state.list,
  pagination: (state) => state.pagination,
  actionOptions: (state) => {
    const options = state.actions.map((action) => {
      const option = { label: actionTypes[action], value: action.toLowerCase() };
      return option;
    });
    const sortedOptions = sortBy(options, (o) => o.label);
    sortedOptions.unshift({ label: 'Tümü', value: '' });
    return sortedOptions;
  },
  userOptions: (state) => {
    const options = state.users.map((action) => {
      const option = { label: action.username, value: action.onedio_user_id };
      return option;
    });
    const sortedOptions = sortBy(options, (o) => o.label);
    sortedOptions.unshift({ label: 'Tümü', value: '' });
    return sortedOptions;
  }
};

export const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  setPagination(state, payload) {
    let total;
    if (Number.isInteger(parseInt(payload.total))) {
      total = payload.total;
    } else {
      total = state.pagination.total;
    }
    state.pagination = {
      page: payload.page || state.pagination.page,
      perPage: payload.perPage || state.pagination.perPage,
      total
    };
  },
  setActions(state, payload) {
    state.actions = payload;
  },
  setUsers(state, payload) {
    state.users = payload;
  }
};

export const actions = {
  async getFilters({ commit }) {
    const users = await AuditService.getUsers();
    const actions = await AuditService.getActions();
    commit('setActions', actions);
    commit('setUsers', users);
  },

  async getList({ dispatch, commit, state, rootState }) {
    commit('setPagination', { total: 0 });
    const { user, action, fromDate, endDate, page, perPage } = rootState.route.query;

    if (!state.users.length) await dispatch('getFilters');
    const result = await AuditService.getAll({ user, action, fromDate, endDate, page, perPage });
    //normalize data
    result.data = result.data.map((item) => {
      if (item.redirectType === 'ENTRY') item.redirectData = normalizeEntry(item.redirectData);
      if (item.redirectType === 'TOPIC') item.redirectData = normalizeTopic(item.redirectData);
      if (item.redirectType === 'USER') item.redirectData = normalizeUser(item.redirectData);
      // todo: backend donecek admini
      item.admin = state.users.find((user) => {
        return user.onedio_user_id === item.userId;
      });
      item.actionText = actionTypes[item.action] || item.action;
      return item;
    });

    commit('setPagination', { total: parseInt(result.count) });
    commit('setList', result.data);
    return result;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

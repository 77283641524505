import http from './http';
import qs from 'qs';
import { oioHeader, authHeader } from '@/utils';
import { API_URL } from '@/constants/config';

const base = API_URL + '/v1/1/4/1';

class InstagramService {
  /**
   * Get all topics in instagram bridge
   *
   * @param {Object} [param] object
   * @param {Number} [param.page] number
   * @param {Number} [param.perPage] number
   */
  async getAll({ perPage = 50, page = 1 } = {}) {
    const params = qs.stringify({ page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/topics/instagram${params}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data;
  }

  /**
   * Add topic to instagram bridge
   *
   * @param {Object} param object
   * @param {string} param.id topic id
   * @param {string} param.thumbnail thumbanil url
   */
  async addTopic({ id, thumbnail }) {
    const body = {
      topicId: id,
      thumbnail
    };
    const { data: result } = await http.post(`${base}/topics/instagram`, body, {
      headers: { ...authHeader() }
    });
    return result;
  }

  /**
   * Remove topic from instagram bridge
   *
   * @param {*} id
   */
  async removeTopic(id) {
    const { data: result } = await http.delete(`${base}/topics/instagram`, { data: { topicId: id }, headers: authHeader() });
    return result.data;
  }
}
export default new InstagramService();

import http from './http';
import qs from 'qs';
import { oioHeader, authHeader } from '@/utils';
import { API_URL } from '@/constants/config';

const base = API_URL + '/v1/1/4/1';

class AuditService {
  /**
   * Get all audit logs
   *
   * @param {Object} [param] object
   * @param {Number} [param.page] number
   * @param {Number} [param.perPage] number
   * @param {String} [param.user] user - filter
   * @param {String} [param.action] action - filter
   * @param {String} [param.startDate] start date - filter
   * @param {String} [param.endDate] end date - filter
   */
  async getAll({ user, action, fromDate, endDate, perPage = 50, page = 1 } = {}) {
    const params = qs.stringify(
      { page, perPage, user, action: action && action.toUpperCase(), startDate: fromDate, finishDate: endDate },
      { addQueryPrefix: true }
    );
    const { data: result } = await http.get(`${base}/audit/log${params}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data;
  }

  /**
   * Get audit users for audit
   */
  async getUsers() {
    const { data: result } = await http.get(`${base}/audit/user`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data.data; // todo: backend refactor
  }

  /**
   * Get audit users for audit
   */
  async getActions() {
    const { data: result } = await http.get(`${base}/audit/action`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data;
  }
}
export default new AuditService();

import http from './http';
import qs from 'qs';
import { authHeader, oioHeader } from '@/utils';
import { API_URL } from '@/constants/config';

const base = API_URL + '/v1/1/4/1';

class ComplaintService {
  /**
   * Get by type
   *
   * @param {Object} params
   * @param {string} params.type type ENTRY | TOPIC | USER
   * @param {Number} params.page page
   * @param {Number} params.perPage perPage
   */
  async getByType({ type, perPage = 50, page = 1, orderKey, orderValue } = {}) {
    const params = qs.stringify({ type, page, perPage, orderKey, orderValue }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/complaint/all${params}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return { data: result.data.complaints, count: result.data.count };
  }

  /**
   * Get by target
   *
   * @param {Object} params
   * @param {string} params.type type ENTRY | TOPIC | USER
   * @param {String} params.target target id
   * @param {Number} params.page page
   * @param {Number} params.perPage perPage
   */
  async getByTarget({ type, target, perPage = 50, page = 1 } = {}) {
    const params = qs.stringify({ type, target, page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/complaint/target${params}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return { data: result.data.complaints, target: result.data.targetData };
  }

  async getByComplaintDetail(id) {
    const { data: result } = await http.get(`${base}/complaint/getComplaintByID/${id}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return { data: result.data };
  }
  async getByMessage(tab) {
    const { data: result } = await http.get(`${base}/message/template/${tab}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    let keyArray = [];
    let valueArray = {};
    for (let i = 0; i < result.data.length; i++) {
      const key = Object.keys(result.data[i])[0];
      const value = Object.values(result.data[i])[0];
      keyArray.push({ label: key });
      valueArray[key] = value;
    }
    return { data: keyArray, target: valueArray };
  }
}
export default new ComplaintService();

import TopicService from '@/services/TopicService';
import { normalizeTopic } from '@/utils';
import UploadService from '@/services/UploadService';

export const state = () => ({
  list: [],
  topicListType: 'all', // 'all' 'deleted' 'waiting'
  isMerging: false,
  mergeList: [],
  searchText: '',
  pagination: {
    page: 1,
    perPage: 50,
    total: 0
  }
});

export const getters = {
  list: (state) => state.list,
  filterType: (state) => state.topicListType,
  total: (state) => state.pagination.total,
  pagination: (state) => state.pagination,
  mergeList: (state) => state.mergeList,
  isMerging: (state) => state.isMerging,
  currentState: (state) => {
    let statesType = {
      all: false,
      deleted: false,
      waiting: false,
      approved: false,
      restored: false
    };
    statesType[state.topicListType] = true;
    return statesType;
  }
};

export const mutations = {
  setPagination(state, payload) {
    let total;
    if (Number.isInteger(parseInt(payload.total))) {
      total = payload.total;
    } else {
      total = state.pagination.total;
    }
    state.pagination = {
      page: payload.page || state.pagination.page,
      perPage: payload.perPage || state.pagination.perPage,
      total
    };
  },
  setList(state, payload) {
    state.list = payload;
  },
  setMergeList(state, payload) {
    state.mergeList = payload;
  },
  setIsMerging(state, payload) {
    state.isMerging = payload;
  },
  setTopicListType(state, payload) {
    state.topicListType = payload;
  },
  setSearchText(state, payload) {
    state.searchText = payload;
  }
};

export const actions = {
  async approveTopic({ state, dispatch }, id) {
    const result = await TopicService.approve(id);
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async deleteTopic({ state, dispatch }, id) {
    const result = await TopicService.delete(id);
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async restoreTopic({ state, dispatch }, id) {
    const result = await TopicService.restore(id);
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async pinTopic({ state, dispatch }, payload) {
    const result = await TopicService.pin(payload);
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async pickTopic({ state, dispatch }, payload) {
    const result = await TopicService.pick(payload);
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async lockTopic({ state, dispatch }, payload) {
    const result = await TopicService.lock(payload);
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async upPickedTopic({ state, dispatch }, payload) {
    const result = await TopicService.up(payload);
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async changeThumbnail({ state, dispatch }, payload) {
    let formData = new FormData();
    formData.append('image', payload.image);
    const upload = await UploadService.upload(formData);
    const thumbnail = upload.urlEncodedFileName;
    const result = await TopicService.changeThumbnail({ id: payload.id, thumbnail });
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async searchTopics({ commit }, payload) {
    commit('setList', []);
    const result = await TopicService.search(payload);
    const list = result.data.map(normalizeTopic);
    commit('setPagination', { total: result.count });
    commit('setList', list);
    return result;
  },
  async setListFromMerge({ commit, state }) {
    commit('setList', state.mergeList);
  },
  async setListFromSearch({ commit, state }) {
    commit('setList', state.searchList);
  },
  async getTopicsByType({ commit }, type) {
    const result = await TopicService.allByType(type);
    const list = result.data.map(normalizeTopic);
    commit('setPagination', { total: result.count });
    commit('setList', list);
  },
  async getTopicById({ commit }, { id }) {
    commit('setList', []);
    const result = await TopicService.getById(id);
    const list = [normalizeTopic(result)];
    commit('setList', list);
    return result;
  },
  async mergeTopics({ state, commit }, { body, filterList }) {
    const filteredMergeList = state.mergeList.filter((mergeItem) => !filterList.includes(mergeItem.id));
    await TopicService.merge(body);

    commit('setList', filteredMergeList);
    commit('setMergeList', filteredMergeList);
  },
  async updateTopic({ state, dispatch }, payload) {
    const result = await TopicService.update(payload);
    normalizeTopic(result);
    dispatch('getTopics', state.topicListType);
    return result;
  },
  async getTopics({ rootState, commit, dispatch }, type) {
    commit('setIsMerging', false);
    commit('setTopicListType', type);
    commit('setPagination', { total: 0 });
    const query = rootState.route.query;
    if (query.id) {
      await dispatch('getTopicById', { id: query.id, ...query });
    } else if (query.search) {
      await dispatch('searchTopics', query);
    } else {
      await dispatch('getTopicsByType', { type, ...query });
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

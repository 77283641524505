import http from './http';
import qs from 'qs';
import { oioHeader, authHeader } from '@/utils';
import { API_URL } from '@/constants/config';

const base = API_URL + '/v1/1/4/1';

class BroadcastService {
  /**
   * Broadcast List
   *
   * @param {Object} [param] object
   * @param {Number} [param.page] number
   * @param {Number} [param.perPage] number
   */
  async getAll({ perPage = 50, page = 1 } = {}) {
    const params = qs.stringify({ page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/broadcastMessages${params}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data;
  }

  /**
   * Broadcast
   *
   * @param {Object} param object
   * @param {string} param.text message
   */
  async send({ message }) {
    const body = {
      message: {
        messageText: message
      }
    };
    const { data: result } = await http.post(`${base}/broadcast/conversation/send`, body, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result;
  }

  /**
   * Send a message to a user
   *
   * @param {Object} param object
   * @param {string} param.text message
   * @param {string} param.userId user id
   */
  async sendMessage({ message, userId }) {
    const body = {
      targetUserId: userId,
      message: {
        messageText: message
      }
    };
    const { data: result } = await http.post(`${base}/conversation/sendMessageFromAdminPanel`, body, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result;
  }
}
export default new BroadcastService();

/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import App from './App.vue';

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);

// axios
import axios from 'axios';
Vue.prototype.$http = axios;

// Theme Configurations
import '../themeConfig.js';

//cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
VueCookies.config('7d');

import VueQuillEditor from 'vue-quill-editor/dist/ssr';
Vue.use(VueQuillEditor);

import VueSanitize from 'vue-sanitize';
var defaultOptions = {
  allowedTags: ['p', 'b', 'h2', 'h3', 'h4', 'h5', 'h6']
};
Vue.use(VueSanitize, defaultOptions);

// ACL
import acl from './acl/acl';

// Globally Registered Components
import './globalComponents.js';

// Styles: SCSS
import './assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from './router';

// Vuex Store
import store from './store/store';

// i18n
import i18n from './i18n/i18n';

// Vuesax Admin Filters
import './filters/filters';

// Clipboard
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// Tour
import VueTour from 'vue-tour';
Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');

// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);

// Feather font icon
require('./assets/css/iconfont.css');

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

import { sync } from 'vuex-router-sync';
//eslint-disable-next-line
const unsync = sync(store, router);

// sweetalert
import VueSwal from 'vue-swal';

Vue.use(VueSwal);
new Vue({
  router,
  store,
  i18n,
  acl,
  render: (h) => h(App)
}).$mount('#app');

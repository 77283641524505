/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const actions = {

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    updateSidebarWidth({ commit }, width) {
      commit('UPDATE_SIDEBAR_WIDTH', width);
    },
    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY');
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val);
    },
    updateUserRole({ commit }, val) {
      commit('UPDATE_USER_ROLE', val);
    },
    updateWindowWidth({ commit }, width) {
      commit('UPDATE_WINDOW_WIDTH', width);
    },


    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    //  The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    topic_entry({ commit }, topic) {
      commit('SET_TOPIC_ENTRY', topic);
    },

    merge_topic({ commit }, item) {
      commit('SET_MERGE_TOPIC', item);
    },
    merge_topic_id({ commit }, item) {
      commit('SET_MERGE_TOPIC_ID', item);
    },
    remove_merge_topic({ commit }, item) {
      commit('REMOVE_MERGE_TOPIC', item);
    },
    remove_merge_topic_id({ commit }, item) {
      commit('REMOVE_MERGE_TOPIC_ID', item);
    },
    remove_all_merge_topic({ commit }) {
      commit('REMOVE_ALL_MERGE_TOPIC');
    },
    remove_all_merge_topic_id({ commit }) {
      commit('REMOVE_ALL_MERGE_TOPIC_ID');
    },
}

export default actions

/**
 * Self explanatory
 * ^.^
 */
const statusMessages = {
  200: 'OK.',
  201: 'İşlem başarı ile gerçekleştirildi.',
  400: 'İşlemi gerçekleştirirken bir hata meydana geldi.',
  // 401: 'Oturumunuz zaman aşımına uğradı. Lütfen tekrar giriş yapın.',
  404: 'Sonuç Bulunamadı. 404.',
  503: 'İşlem Gerçekleştirilemiyor. Geçiçi bir sorun olabilir.',
  generic: 'Beklenmedik bir hata meydana geldi. Lütfen tekrar deneyin.'
};

/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage(status) {
  let message = '';
  if (statusMessages[status]) {
    message = statusMessages[status];
  } else {
    message = statusMessages['generic'];
  }
  return message;
}

function _getResponseErrorMessage(error) {
  if (error.response && error.response.data) return error.response.data.description;
  if (error.response && error.response.statusText) return error.response.statusText;
  if (error.message) return error.message;
  return "_getResponseErrorMessage can't handle error";
}

/**
 * Create instant, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
export class ResponseWrapper {
  constructor(response, data = {}, message) {
    this.data = data;
    this.success = response.data.success;
    this.status = response.status;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = message || null;
  }
}

/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
  constructor(error, message) {
    super();
    this.name = 'ErrorWrapper';
    this.stack = new Error().stack;
    this.success = error.response ? error.response.data.success : false;
    this.status = error.response ? error.response.status : false;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = message || _getResponseErrorMessage(error);
  }
}

/**
 * Just a wrapper
 *
 * @param  {...any} rest
 */
export const responseWrapper = (...rest) => {
  return new ResponseWrapper(...rest);
};

/**
 * Just a wrapper
 *
 * @param  {...any} rest
 */
export const errorWrapper = (...rest) => {
  return new ErrorWrapper(...rest);
};

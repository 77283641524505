import BroadcastService from '@/services/BroadcastService';

const getMessageState = () => ({
  subject: '',
  message: '',
  pushNotification: false
});

export const state = () => ({
  newMessage: getMessageState(),
  list: []
});

export const getters = {
  list: (state) => state.list
};

export const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  setNewMessage(state, payload) {
    state.newMessage = payload;
  }
};

export const actions = {
  async getAll({ commit }) {
    const result = await BroadcastService.getAll();
    commit('setList', result);
    return result;
  },
  resetNewMessage({ commit }) {
    commit('setNewMessage', getMessageState());
  },
  async sendBroadcast({ dispatch, state }) {
    const payload = state.newMessage;
    const result = await BroadcastService.send(payload);
    await dispatch('getAll');
    return result;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem('user');

  if (token) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}

export function oioHeader() {
  let token = localStorage.getItem('appToken');

  if (token) {
    return { 'oio-token': token };
  } else {
    return {};
  }
}

// todo: convert to mappers
export const normalizeTopic = (item) => {
  if (!item) {
    item = {};
  }
  if (!item.topic) {
    item.topic = '-';
  }

  item.user = normalizeUser(item.user);
  item = normalizeState(item);
  return item;
};

export const normalizeEntry = (item) => {
  item.topic = normalizeTopic(item.topic);
  item.user = normalizeUser(item.user);
  item = normalizeState(item);
  return item;
};

export const normalizeUser = (item) => {
  if (!item) {
    item = {};
  }
  if (!item.username) {
    item.username = '-';
  }
  if (!item.onedio_user_id) {
    let id = '-'; // yoksa
    if (item.id) id = item.id; // todo: backend refactor etmeli
    if (item.userId) id = item.userId; // todo: backend refactor etmeli
    item.onedio_user_id = id;
  }

  if (item.is_admin_verified) {
    item.state = 'approved';
  }
  if (!item.is_admin_verified) {
    item.state = 'waiting';
  }
  if (item.ban_status) {
    item.state = 'banned';
  }
  return item;
};

const normalizeState = (item) => {
  if (!item) {
    return;
  }
  if (!item.approved) {
    item.state = 'waiting';
  }
  // Todo: Simdilik kullanilmiyor.
  // if (item.published) {
  //   item.state = 'restored';
  // }
  if (item.approved) {
    item.state = 'approved';
  }
  if (item.deleted) {
    item.state = 'deleted';
  }
  return item;
};

/**
 * Remote Logging
 */

function init() {}

function log(error) {
  // TODO: Add service
  console.error(error);
}

export default {
  init,
  log
};

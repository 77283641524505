import http from './http';
import qs from 'qs';
import { authHeader, oioHeader } from '@/utils';
import { API_URL, USER_URL, SEARCH_URL } from '@/constants/config';

const currentUser = USER_URL + '/users';
const base = API_URL + '/v1/1/4/1';
const baseSearch = SEARCH_URL + '/v1/1/4/4/search/user';

class UserService {
  /**
   * Get logged in user info
   */
  async me() {
    try {
      const { data: result } = await http.get(`${currentUser}/me`, {
        headers: { ...authHeader(), ...oioHeader() }
      });
      return result.data;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * All users
   */
  async all({ perPage = 50, page = 1 } = {}) {
    const params = qs.stringify({ page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/user/getUsers${params}`, { headers: authHeader() });
    return { data: result.data.users, count: result.data.count };
  }

  /**
   * Banned users
   */
  async getBanned({ perPage = 50, page = 1 } = {}) {
    const params = qs.stringify({ page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/users/banned${params}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return { data: result.data.users, count: result.data.count };
  }

  /**
   * Get by verify status
   *
   * @param {id: string, page: int. perPage: int } params
   */
  async getByVerifyStatus({ status = false, perPage = 50, page = 1 } = {}) {
    const params = qs.stringify({ isVerified: status, page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/users/list/adminVerified${params}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return { data: result.data.users, count: result.data.count };
  }

  /**
   * Get user by id
   * @param {String} id
   */
  async getById(id) {
    const { data: result } = await http.get(`${base}/user/getUserById/${id}`, { headers: { ...authHeader(), ...oioHeader() } });
    return result.data;
  }

  /**
   * Verify a user
   * @param {*} id
   *
   */
  async verify(userId) {
    const body = { userId, isAdminVerified: true };
    const { data: result } = await http.post(`${base}/users/verify/adminVerified`, body, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data;
  }

  /**
   * Ban a user
   * @param {*} id
   *
   */
  async ban({ userId, cause, duration }) {
    const body = { userId, cause, duration };
    const { data: result } = await http.post(`${base}/user/ban`, body, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data;
  }

  /**
   * Unban a user
   * @param {*} id
   *
   */
  async unban(userId) {
    const { data: result } = await http.delete(`${base}/user/disban/${userId}`, {
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data;
  }

  /**
   * Delete a user
   * @param {*} id
   *
   */
  async delete(userId) {
    const body = { userId };
    // axios.delete ile body gonderemiyoruz ilginc bi sekilde
    const { data: result } = await http({
      url: `${base}/user/delete`,
      method: 'delete',
      data: body,
      headers: { ...authHeader(), ...oioHeader() }
    });
    return result.data;
  }

  /**
   * Search users
   *
   * @param {{search, page: int. perPage: int}} params
   */
  async search({ search = '', perPage = 50, page = 1 }) {
    const params = qs.stringify({ q: search, page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${baseSearch}${params}`, { headers: authHeader() });
    return { data: result.data.paginatedSearchResult, count: result.data.totalCount };
  }
}
export default new UserService();

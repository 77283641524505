import http from './http';
import qs from 'qs';
import { authHeader } from '@/utils';
import { API_URL, SEARCH_URL } from '@/constants/config';

const base = API_URL + '/v1/1/4/1';
const baseSearch = SEARCH_URL + '/v1/1/4/4/search/topic';

class TopicService {
  /**
   * All topics
   */
  async all({ type = 'all', perPage = 50, page = 1 } = {}) {
    const params = qs.stringify({ type, page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/topic${params}`, { headers: authHeader() });
    return result.data;
  }

  /**
   * All topics by type
   *
   * @param {String} type 'waiting' | 'deleted' | 'approved'
   */
  async allByType({ type = 'all', perPage = 50, page = 1, orderKey, orderValue } = {}) {
    const params = qs.stringify({ type, page, perPage, orderKey, orderValue }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/topic/${params}`, { headers: authHeader() });
    return result.data;
  }

  /**
   * All topics by type
   *
   * @param {String} type 'waiting' | 'deleted' | 'approved'
   */
  async getById(id) {
    const { data: result } = await http.get(`${base}/topic/getByID/${id}`, { headers: authHeader() });
    return result.data;
  }

  /**
   * All entries by topic id
   *
   * @param {id: string, page: int. perPage: int } params
   */
  async getEntriesById({ id, perPage = 50, page = 1 }) {
    const params = qs.stringify({ topicId: id, page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${base}/entry/list/${params}`, { headers: authHeader() });
    return result.data;
  }

  /**
   * Approve a topic
   * @param {*} id
   *
   */
  async approve(id) {
    const { data: result } = await http.post(`${base}/topic/approve?topicId=` + id, {}, { headers: authHeader() });
    return result.data;
  }

  /**
   * Update a topic
   * @param {*} param
   *
   */
  async update({ id, topic }) {
    const body = { id, topic };
    const { data: result } = await http.put(`${base}/topic/update`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Delete topic
   *
   * @param {*} id
   */
  async delete(id) {
    const { data: result } = await http.delete(`${base}/topic/delete/soft/${id}`, { headers: authHeader() });
    return result.data;
  }

  /**
   * Restore topics
   *
   * @param {[]} id
   */
  async restore(id) {
    const body = {
      topicId: id
    };
    const { data: result } = await http.post(`${base}/topic/restore`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Search topics
   *
   * @param {search: string, page: string, perPage: string} topic
   */
  async search({ search = '', perPage = 50, page = 1 }) {
    const params = qs.stringify({ q: search, page, perPage }, { addQueryPrefix: true });
    const { data: result } = await http.get(`${baseSearch}${params}`, { headers: authHeader() });
    return { data: result.data.paginatedSearchResult, count: result.data.totalCount };
  }
  /**
   * Merge topics
   *
   * @param {id: integer, otherTopicIds: []} params
   */
  async merge({ id, otherTopicIds }) {
    const body = { mergedTopicId: id, otherTopics: otherTopicIds };
    const { data: result } = await http.post(`${base}/topic/merge`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Pin topic
   *
   * @param {Object} param object
   * @param {string} poram.id topic id
   * @param {boolean} poram.pin state
   */
  async pin({ id, pin }) {
    const body = {
      topicId: id,
      pin
    };
    const { data: result } = await http.post(`${base}/topic/pin`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Pick topic
   *
   * @param {Object} param object
   * @param {string} poram.id topic id
   * @param {boolean} poram.pick state
   */
  async pick({ id, pick }) {
    const body = {
      topicId: id,
      pick
    };
    const { data: result } = await http.post(`${base}/topic/pick`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Lock topic
   *
   * @param {Object} param object
   * @param {string} poram.id topic id
   * @param {boolean} poram.lock state
   */
  async lock({ id, lock }) {
    const body = {
      topicId: id,
      lock
    };
    const { data: result } = await http.post(`${base}/topic/lock`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Update picked topic
   *
   * @param {Object} param object
   * @param {string} poram.id picked topic id
   */
  async up({ id }) {
    const body = {
      topicId: id
    };
    const { data: result } = await http.post(`${base}/topic/up`, body, { headers: authHeader() });
    return result.data;
  }

  /**
   * Change topic thumbnail
   *
   * @param {Object} param object
   * @param {string} poram.id topic id
   * @param {string} poram.thumbnail thumbnail string after upload
   */
  async changeThumbnail({ id, thumbnail }) {
    const body = {
      topicId: id,
      thumbnail
    };
    const { data: result } = await http.post(`${base}/thumbnail/set`, body, { headers: authHeader() });
    return result.data;
  }
}
export default new TopicService();
